import { MediaManagerService } from 'src/app/Managers/media-manager.service';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild, ChangeDetectorRef
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { ModifyVideoManagerService } from "src/app/Managers/modify-video-manager.service";
import { ModifyVideo } from "../../../../models/modify-video";
import { mediaFile, visual } from "../../../../models/media";
import { cSessionService } from "src/app/services/session.service";
import { ErrorService } from "src/app/services/error.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { sap_config, video } from "src/app/models/video";
import { CreateVideoManagerService } from "src/app/Managers/create-video-manager.service";
import { LoaderService } from "src/app/services/loader.service";
import { VideoService } from "../../../../services/dataServices/video.service";
import { SuccessMessageModalService } from "src/app/services/success-message-modal.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { UploadFileDialogComponent } from "src/app/shared/components/upload-file-dialog/upload-file-dialog.component";
import { takeUntil } from 'rxjs/operators';
import { VideoDataStore } from 'src/app/stores/video.store';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-mv-overview",
  templateUrl: "./sap-overview.component.html",
  styleUrls: ["./sap-overview.component.scss"],
})
export class MvOverviewComponent implements OnInit, OnDestroy {
  @ViewChild('fpsInput') fpsInput!: ElementRef; 
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild("closeModal") closeModal: ElementRef;
  @ViewChild("close") close: ElementRef;
  @ViewChild("videoPreview") videoPreview: ElementRef;
  sSessionID: string = "";
  sUserEmail: string = "";
  uploadedVdeo: any;
  lUserSelectedVisuals: any = [];
  fOverviewForm: FormGroup;
  isUploadVideo: boolean = false;
  bAuthorizationError: boolean = false;
  sDisplayResponseErrorMessage: string;
  errorModal: boolean = false;
  isClassAdded = false;
  enableCustomFpsInput = false;
  customFpsValue : any;
  submitFormSubscription: Subscription;
  countForlouderBrand = 0;
  constructor(
    public modifyVideoObj: ModifyVideo,
    public modifyVideoManager: ModifyVideoManagerService,
    private router: Router,
    private oSessionService: cSessionService,
    private errorService: ErrorService,
    public fOverviewFormBuilder: FormBuilder,
    public videoServie: VideoService,
    public videoManager: CreateVideoManagerService,
    public loader: LoaderService,
    private successModal: SuccessMessageModalService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private mediaManager: MediaManagerService,
    public videoStore: VideoDataStore,
    private onBoarding : OnboardingManagerService
  ) { }

  ngOnInit(): void {
    if( this.onBoarding.profile.organization_id == environment.LouderId || this.onBoarding.profile.organization_id == environment.client_id){
    this.countForlouderBrand = 1;
    }
    this.videoStore.clearGenerationInterval = false;
    this.modifyVideoManager.isPreview = true;
    this.sSessionID = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

    this.videoServie.firstTimeCreateVideoMixPixelApi({
      email : this.sUserEmail,
      session_id : this.sSessionID,
      event : 'create_video_landing',
      param : {
        user_type : this.onBoarding.profile.plan != 'enterprise' ? 'smb' : 'enterprise',
        video_type : 'sap'
      }
  });
    this.submitFormSubscription = this.videoManager.submitForm.subscribe(
      (c) => {
        this.submitForm(c);
      }
    );

    if (this.videoManager.isEditMode) {
      // this.videoManager.getVideo()
    }
    if (this.videoManager.selectedVideo) {
      this.CreateVideoOverviewComponent_SetOverviewForm();

      if (this.videoManager.selectedVideo.sap_video_id) {

        setTimeout(() => {
          let thumb = this.videoManager.selectedVideo.sap_video_url_thumb;
          if (this.videoManager?.selectedVideo?.thumbnail) {
            thumb = this.videoManager.selectedVideo.thumbnail;
          }
          this.uploadedVdeo = {
            thumb: thumb,
            fpath: this.videoManager.selectedVideo.sap_video_id,
            url: this.videoManager.selectedVideo.video_url,
            sap_video_duration:
              this.videoManager.selectedVideo.sap_video_duration,
          };
          this.isUploadVideo = true;
          this.cd.detectChanges();
        }, 100);
      }
    } else {
      this.CreateVideoOverviewComponent_InitializeOverviewForm();
    }

    // if (this.modifyVideoObj.videoObj != '') {
    //   this.uploadedVdeo = this.modifyVideoObj.videoObj;
    //   this.isUploadVideo = true;
    // }
  }

  CreatedVideosComponent_PlayVideo(nIncommingVideoId) {
    var video = <HTMLVideoElement>document.getElementById(nIncommingVideoId);

    video.play();
  }

  ngOnDestroy() {
    this.submitFormSubscription.unsubscribe();
    if (this.videoManager.isEditMode) {
      this.updateVideoLocally();
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    // this.mediaManager.replaceMediaFromVisualPopup.unsubscribe();
  }
  CreateVideoOverviewComponent_SetOverviewForm() {
    this.fOverviewForm = this.fOverviewFormBuilder.group({
      session_id: [this.sSessionID],
      email: [this.sUserEmail],
      title: [this.videoManager.selectedVideo.title, [Validators.required]],
      fps: [this.videoManager.selectedVideo.fps, [Validators.required]],
      video_id: [this.videoManager.selectedVideo.fpath],
    });

  }

  CreateVideoOverviewComponent_InitializeOverviewForm() {
    this.fOverviewForm = this.fOverviewFormBuilder.group({
      session_id: [this.sSessionID],
      email: [this.sUserEmail],
      title: ["", [Validators.required]],
      fps: [24, [Validators.required]],
      
    });
  }

  private updateVideoLocally() {
    let vid = new video();

    vid.session_id = this.fOverviewForm.value.session_id;
    vid.email = this.fOverviewForm.value.email;
    vid.title = this.fOverviewForm.value.title;
    vid.fps = this.fOverviewForm.value.fps;
    // vid.sap_config = new sap_config();
    // vid.sap_config = null;
    // vid.sap_config.duration =30;
    vid.duration = 30;
    var v = <HTMLVideoElement>document.getElementById(this.uploadedVdeo.fpath);
    vid.sap_video_duration = this.uploadedVdeo.sap_video_duration
      ? this.uploadedVdeo.sap_video_duration
      : v.duration;

    vid.template_category = "sales/event";
    vid.template_name = "SAP";
    vid.aspect_ratio = "16x9";

    // vid.uploaded_video_id =  this.uploadedVdeo.fpath;
    vid.sap_video_id = this.uploadedVdeo.fpath;
    if (
      this.videoManager?.selectedVideo &&
      this.videoManager?.selectedVideo?.fpath
    ) {
      vid.fpath = this.videoManager.selectedVideo.fpath;
    }

    // vid.fpath =   this.videoManager.selectedVideo.fpath;
    // vid.sap_video_id = "media/SAP/parrot-pexels-connor-kane-5287270.mp4";

    return vid;
  }

  submitForm(submitFrom: string) {

    if (this.fOverviewForm.valid && this.isUploadVideo != false) {
      let vid = this.updateVideoLocally();
      vid.step = 'overview';
      this.videoManager
        .createVideo(vid)
        .then((res) => {
          vid["video_id"] = res["video_id"];
          vid.fpath = res["fpath"];
          this.videoManager.selectedVideo = vid;
          this.videoManager.selectedVideo.thumbnail = this.uploadedVdeo.thumb;
          this.videoManager.selectedVideo.fpath = res["fpath"];
          this.videoManager.selectedVideo.video_id = res["video_id"];

          // this.videoManager.selectedVideo.video_id = vid.fpath = res["fpath"];


          if (!this.videoManager.isEditMode) {
            this.router.navigateByUrl("/mv/looknfeel");
          } else {
            this.router.navigateByUrl("/mv/preview");
          }
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);
          this.bAuthorizationError = false;

          let oErrorMessage = JSON.parse(err)["errorType"];

          if (oErrorMessage === "AuthorizationError") {
            this.bAuthorizationError = true;
            this.sDisplayResponseErrorMessage =
              "You have either exceeded your monthly video limit or not allowed to perform this operation";
            // $('#error-popup').modal("show");
            this.errorModal = true;
          } else {
            this.sDisplayResponseErrorMessage =
              "An internal error occurred. Please try again later.";
            // $('#error-popup').modal("show");
            this.errorModal = true;
          }
          // console.log(err);
        });
    } else {
      this.fOverviewForm.markAllAsTouched();
      this.isUploadVideo = true;
    }
  }

  startUploadProcess = true;
  @ViewChild("videoValidator") videoValidator: ElementRef;
  // UploadVideo(event: any) {
  //   this.loader.hideLoader();
  //   this.closeModal.nativeElement.click();

  //   this.successModal.success(
  //     "Your video is uploading, Please wait.",
  //     "Uploading Video File",
  //     true,
  //     false
  //   );
  //   var videoTag = document.getElementById("myVideo");
  //   const __this = this;

  //   if (event.target.files && event.target.files[0]) {
  //     var reader = new FileReader();
  //     reader.onload = function (e) {
  //       __this.videoValidator.nativeElement.src = e.target.result;
  //       __this.videoValidator.nativeElement.onloadedmetadata = function (
  //         eventLoad
  //       ) {
  //         setTimeout(function () {
  //           try {
  //             //  console.log( " is undefined ", eventLoad.target.webkitAudioDecodedByteCount != undefined );
  //             if (eventLoad.target.webkitAudioDecodedByteCount != undefined) {
  //               // console.log(" checking value of sound file ");
  //               // console.log( " eventLoad.target.webkitAudioDecodedByteCount ", eventLoad.target.webkitAudioDecodedByteCount);
  //               if (eventLoad.target.webkitAudioDecodedByteCount === 0) {
  //                 // console.log("Stop upload process");
  //                 __this.startUploadProcess = false;
  //               } else {
  //                 __this.startUploadProcess = true;
  //               }
  //             }

  //             // console.log("INTO ELSE CASE ");
  //             if (
  //               eventLoad.target.audioTracks != undefined &&
  //               eventLoad.target.audioTracks.length > 0
  //             ) {
  //               if (eventLoad.target.audioTracks[0].id === 0) {
  //                 // console.log("Stop upload process");
  //                 __this.startUploadProcess = false;
  //               } else {
  //                 __this.startUploadProcess = true;
  //               }
  //             }
  //             if (__this.startUploadProcess) {
  //               __this.ContentComponent_UploadFile(event.target.files[0]);
  //             } else {
  //               console.log("Hide modal /.//");
  //               __this.loader.hideLoader();
  //               __this.hideUploadingPopUP();
  //               __this.successModal.close();
  //               __this.closeModal.nativeElement.click();
  //               __this.errorService.handleError(
  //                 "There is no sound in clip",
  //                 "Upload Video",
  //                 true,
  //                 true
  //               );
  //             }
  //           } catch (e) {
  //             __this.ContentComponent_UploadFile(event.target.files[0]);
  //           }
  //         }, 1500);
  //       };
  //     }.bind(this);

  //     reader.readAsDataURL(event.target.files[0]);
  //   }

  //   // t
  // }

  // async ContentComponent_UploadFile(fileToUpload: any) {
  //   setTimeout(() => {
  //     this.closeModal.nativeElement.click();
  //   }, 100);
  //   // this.loader.showLoader();

  //   // this.showUploadingPopUP();
  //   let totalsec = 0;
  //   const video = document.createElement("video");
  //   video.preload = "metadata";
  //   try {
  //     video.onloadedmetadata = async () => {
  //       window.URL.revokeObjectURL(video.src);
  //       totalsec = video.duration; // here you could get the duration

  //       if (totalsec >= 16 && totalsec <= 120) {
  //         let media = new mediaFile();
  //         media.file = fileToUpload;
  //         media.name = fileToUpload.name.split(".").slice(0, -1).join(".");

  //         try {
  //           await this.modifyVideoManager
  //             .uploadMediaDefaultLibrary(
  //               this.sUserEmail,
  //               this.sSessionID,
  //               media
  //             )
  //             .then(
  //               (result: visual) => {
  //                 if (result) {
  //                   this.isUploadVideo = true;
  //                   this.uploadedVdeo = {
  //                     filename: result.name,
  //                     fpath: result.visual_path,
  //                     duration: video.duration.toFixed(2),
  //                     thumb: result.thumbnail,
  //                     url: result.url,
  //                     size: fileToUpload.size,
  //                   };

  //                   // this.loader.hideLoader();
  //                   // this.hideUploadingPopUP();
  //                   this.successModal.close();
  //                 }
  //               },
  //               (error) => {
  //                 // this.loader.hideLoader();
  //                 // this.hideUploadingPopUP();
  //                 this.successModal.close();
  //                 console.error(
  //                   "ContentComponent_UploadFile : Error ==>",
  //                   error
  //                 );

  //                 this.errorService.handleError(
  //                   error.errorMessage ? error.errorMessage : error.error,
  //                   // "An error occurred while creating video. Please try again.",
  //                   error.errorType,
  //                   true,
  //                   true
  //                 );

  //                 setTimeout(() => {
  //                   this.closeModal.nativeElement.click();
  //                 }, 1000);
  //               }
  //             );
  //         } catch (error) {
  //           // this.loader.hideLoader();
  //           // this.hideUploadingPopUP();
  //           this.successModal.close();
  //           this.errorService.handleError(
  //             error.errorMessage ? error.errorMessage : error.error,
  //             error.errorType,
  //             true
  //           );
  //           console.error("ContentComponent_UploadFile : ERROR ==>", error);
  //         }
  //       } else {
  //         // this.loader.hideLoader();
  //         // this.hideUploadingPopUP();
  //         this.successModal.close();
  //         this.errorService.handleError(
  //           "Video duration should be between 15 seconds and 2 minutes. Please upload a video with a duration within this range to continue.",
  //           "Invalid Video Duration",
  //           true
  //         );
  //       }
  //     };

  //     video.src = window.URL.createObjectURL(fileToUpload);
  //   } catch (error) {
  //     this.loader.hideLoader();
  //     this.successModal.close();
  //     this.errorService.handleError(
  //       error.errorMessage ? error.errorMessage : error.error,
  //       // "An error occurred while creating video. Please try again.",
  //       error.errorType,
  //       true,
  //       true
  //     );
  //     // this.hideUploadingPopUP();
  //   }
  // }

  openFileUploadModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100wv";
    dialogConfig.height = "100vh";
    dialogConfig.panelClass = "pronto-dialog";
    dialogConfig.closeOnNavigation = true;

    dialogConfig.data = {
      uploadFor: "video_own",
      multipleUpload: false,
      // showUploadingPopUP : true,
      // accept: "video/mp4, video/avi",
      dropBoxText: "",
      heading: "Upload a Video",
      allowDuplicate: true
    };

    let dialogModal = this.dialog.open(UploadFileDialogComponent, dialogConfig);

    dialogModal.afterClosed().subscribe((data: any) => {
      if (data?.status == "success") {
        const uploadedFiles = data.result[0]
        this.oSessionService.oUploadedLogo = uploadedFiles;
        this.isUploadVideo = true;
        this.uploadedVdeo = {
          filename: uploadedFiles.name,
          fpath: uploadedFiles.visual_path,
          duration: uploadedFiles.duration.toFixed(2),
          thumb: uploadedFiles.thumbnail,
          url: uploadedFiles.url,
          size: uploadedFiles.size,
          allowDuplicate: true
        };

        this.isUploadVideo = true;
        this.cd.detectChanges();
      } else {

      }
    });
    this.mediaManager.replaceMediaFromVisualPopup.pipe(takeUntil(this.destroy$)).subscribe((item) => {

      if (item && item.length > 0) {
        const uploadedFiles: any = item[0]
        this.oSessionService.oUploadedLogo = uploadedFiles;
        this.isUploadVideo = true;
        this.uploadedVdeo = {
          filename: uploadedFiles.fpath,
          fpath: uploadedFiles.fpath,
          duration: 30,
          sap_video_duration: uploadedFiles.duration,
          thumb: uploadedFiles.thumbnail_signed_url,
          url: uploadedFiles.signed_url,
          size: uploadedFiles.size,
          allowDuplicate: true //Will allow user to replace existing file
        };

        this.isUploadVideo = true;
      }
      this.mediaManager.showMediaReplacementPopup = false;
      this.cd.detectChanges();
    })
  }
  mediaSelected(event) {

    this.lUserSelectedVisuals = event;

    var result = this.lUserSelectedVisuals[0];

    if (this.lUserSelectedVisuals[0] != "") {
      this.loader.showLoader();
      this.videoServie
        .get_video_duration(
          this.sUserEmail,
          this.sSessionID,
          result.visual_path
        )
        .then((res) => {
          this.loader.hideLoader();
          if (res["duration"] >= 16 && res["duration"] <= 120) {
            this.isUploadVideo = true;
            this.uploadedVdeo = {
              filename: result.name,
              fpath: result.visual_path,
              thumb: result.thumbnail_signed_url,
              url: result.signed_url,
            };
            
            this.videoManager.selectedVideo.video_url = result.url;

          } else {
            this.errorService.handleError(
              "Video duration should be between 15 seconds and 2 minutes. Please upload a video with a duration within this range to continue.",
              "Invalid Video Duration",
              true
            );
          }
        })
        .catch((err) => {
          this.loader.hideLoader();
        });
    }

    this.visible = false;
  }
  // modal media library
  visible = false;
  showMediaModal() {

    this.visible = true;
    // this.bDisplayMyMediaSection = true;
  }
  closeMediaModal(): void {
    this.visible = false;
  }

  isModalVisible = false;
  showUploadingPopUP(): void {
    this.isModalVisible = true;
  }
  hideUploadingPopUP(): void {
    this.isModalVisible = false;
  }
  returnCompressFileName(filename) {
    var split = filename?.split('.');
    var filename = split[0];
    var extension = split[1];
    if (filename.length > 15) {
      filename = filename.substring(0, 14);

    }
    var result = filename + '.' + extension;
    return result;
  }
  returnFilename(file){
    if(file.filename && file.filename != ''){
      if(file.filename.length > 20){
        return this.returnCompressFileName(file?.filename?.split('/').pop());
      }
      else{
        return file.filename;
      }
    }
    else{
      return this.returnCompressFileName(file?.fpath?.split('/').pop());
    }
  }
  EditCustomFPS(index){
    if(index == 6){
        this.enableCustomFpsInput = true;
        this.videoStore.lVideoFps[6].isEnabled = false;
        if(this.customFpsValue != ''){
                this.fpsInput.nativeElement.value = parseFloat(this.customFpsValue); 
            }
    }
    else{
        this.enableCustomFpsInput = false;
        this.videoStore.lVideoFps[6].isEnabled = true;
        this.videoStore.lVideoFps[6].checked = false;
        this.isClassAdded = false;

    }
}
updateCustomFPSValue(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement; 
    const val = document.getElementById('fpsInput') as HTMLInputElement;
    if (event.key === '-' || event.key === 'Subtract') {
        event.preventDefault();
      }
    this.customFpsValue = inputElement.value;
    if (event.key === 'Enter' && this.customFpsValue != '') {
    this.videoStore.lVideoFps[6].value = parseFloat(this.customFpsValue);
    this.videoStore.lVideoFps[6].name = this.customFpsValue;
    this.videoStore.lVideoFps[6].checked = true;
    this.videoStore.lVideoFps[6].isEnabled = true;
    console.log('Custom FPS value updated:', this.videoStore.lVideoFps[6].value);
    this.fOverviewForm.controls["fps"].setValue(parseFloat(this.customFpsValue));
    this.enableCustomFpsInput = false;
    this.isClassAdded = true;
}
}
}
