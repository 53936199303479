import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import { Component, ElementRef, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { AccountManagerService } from './../../../Managers/account-manager.service';
import { CreateVideoManagerService } from './../../../Managers/create-video-manager.service';
import { MediaManagerService } from './../../../Managers/media-manager.service';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { mediaFile, visual } from './../../../models/media';
import { video } from './../../../models/video';
import { ErrorService } from './../../../services/error.service';
import { LoaderService } from './../../../services/loader.service';
import { cSessionService } from './../../../services/session.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UploadFileDialogComponent } from 'src/app/shared/components/upload-file-dialog/upload-file-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LocationService } from 'src/app/shared/services/location.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-single-video',
    templateUrl: './single-video.component.html',
    styleUrls: ['./single-video.component.scss'],
})
export class SingleVideoComponent implements OnInit, OnDestroy {
    @ViewChild('fpsInput') fpsInput!: ElementRef; 
    @ViewChild('closeModal') closeModal: ElementRef;
    @ViewChild('videoPreview') videoPreview: ElementRef;
    sSessionID: string = '';
    sUserEmail: string = '';
    oTotalCount: any;
    lLocales: any = [];
    lSelectedLocations: any = [];
    totalSelectedLocals: any = [];
    nCurrentPage = 1;
    lPagination: any = [];
    nPageSize: number = 100;
    uploadedVdeo: any;
    videoPopup: boolean = false;
    vidPreview: boolean = true;
    lUserSelectedVisuals: any = [];
    bDisplayMyMediaSection: boolean = false;
    isModalVisible: boolean = false;
    lClipsList: any = [];
    IsTemplateError = false;
    num_items: number = 20;
    num_skip = 0;
    currentPage = 1;
    listPages: number[] = [];
    destroy$: Subject<boolean> = new Subject<boolean>();
    sub;
    vidTitle = '';
    templateCategories: any;
    selectedTemplate: any;
    sSelectedAspectRatio: any;
    defaultLocationFormField = undefined;
    order_by = 'name';
    sort_type = -1;
    videoAspectRation = '16x9';
    defaultTemplateName = 'DirectLocTemplate1';
    public AllLocaleSelected: boolean = false;
    countForlouderBrand = 0;
    enableCustomFpsInput = false;
    customFpsValue : any;
    isClassAdded =false;
    selectedFps = 24;
    videoAspectRatio = [
        {
            title: 'Landscape',
            icon: '../../../../assets/images/aspect ratio/Landscape.svg',
            ratio: '16:9',
            ratio_value: '16x9',
            isSelected: true,
            class: 'lanscape-size',
            socialIcon: [
                '../../../../assets/images/svg/youtube 2.svg',
                '../../../../assets/images/svg/facebook-icon.svg',
                '../../../../assets/images/svg/linkedin-icon.svg',
            ],
        },
        // {
        //     title: 'Square',
        //     icon: '../../../../assets/images/aspect ratio/Square.svg',
        //     ratio_value: '1x1',
        //     ratio: '1:1',
        //     class: 'square-size',
        //     isSelected: true,
        //     socialIcon: ['../../../../assets/images/svg/instagram.svg'],
        // },
        {
            title: 'Story/Reels',
            icon: '../../../../assets/images/aspect ratio/StoryReels.svg',
            ratio_value: '9x16',
            ratio: '9:16',
            class: 'reel-size',
            isSelected: false,
            socialIcon: [
                '../../../../assets/images/svg/facebook-icon.svg',
                '../../../../assets/images/svg/instagram.svg',
                '../../../../assets/images/svg/linkedin-icon.svg',
            ],
        },
        // {
        //     title: 'Portrait',
        //     icon: '../../../../assets/images/aspect ratio/Portrait.svg',
        //     ratio: '4:5',
        //     class: 'portrait-size',
        //     ratio_value: '4x5',
        //     isSelected: false,
        //     socialIcon: ['../../../../assets/images/svg/instagram.svg'],
        // },
    ];

    lVideoFps = [
        { name: '23.97 ', value: 23.97, checked: false, isEnabled: true },
        { name: '24 ', value: 24, checked: false, isEnabled: true },
        { name: '25 ', value: 25, checked: false, isEnabled: true },
        { name: '29.97 ', value: 29.97, checked: false, isEnabled: true },
        { name: '30 ', value: 30, checked: false, isEnabled: true },
        { name: '60 ', value: 60, checked: false, isEnabled: true },
        // { name: '', value: null, checked: false, isEnabled: true },
      ];
    constructor(
        public videoManager: CreateVideoManagerService,
        public loader: LoaderService,
        private oSessionService: cSessionService,
        public onBoardingManager: OnboardingManagerService,
        private oRoutes: Router,
        private accountManager: AccountManagerService,
        private errorService: ErrorService,
        private mediaManger: MediaManagerService,
        private dialog: MatDialog,
        private cd: ChangeDetectorRef,
        private locationService: LocationService,
    ) {}


    ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
    async ngOnInit() {
        if( this.onBoardingManager.profile.organization_id == environment.LouderId || this.onBoardingManager.profile.organization_id == environment.client_id){
       this.countForlouderBrand = 1;
        }
        this.sSessionID = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
        if(this.videoManager.selectedVideo){
            this.videoManager.selectedVideo.brand_id = undefined;
        }
        this.locationService.getLocationOptions().then((res) => {
            this.defaultLocationFormField = res;
        });

        await this.locationService.getLocationTemplate({ org_id: this.oSessionService.getUserOrganization() }).then((res) => {
            this.templateCategories = res;
            if( this.templateCategories &&  this.templateCategories.length > 0){
                // this.selectedTemplate = this.templateCategories[0].name;
            }
        });
        this.sSelectedAspectRatio = this.videoAspectRatio[0];

        this.sub = this.videoManager.submitForm.subscribe((c) => {
            // console.log(this.uploadedVdeo);

            if (this.uploadedVdeo && this.selectedTemplate && (this.lSelectedLocations.length > 0 || this.AllLocaleSelected)) {
                let vid = new video();

                (vid.email = this.sUserEmail),
                    (vid.session_id = this.sSessionID),
                    (vid.user_clip_id = this.uploadedVdeo.fpath),
                    (vid.fpath = this.uploadedVdeo.fpath),
                    (vid.title = this.vidTitle),
                    (vid.template_name = this.selectedTemplate),
                    (vid.aspect_ratio = this.videoAspectRation),
                    (vid.locales = this.lSelectedLocations),
                    (vid.AllLocaleSelected = this.AllLocaleSelected);
                    (vid.fps = this.selectedFps);

                this.videoManager.DirectLocalizeVideo(vid).then((res) => {
                    this.showPopUp();

                    // check
                });
            } else if (!this.selectedTemplate || this.selectedTemplate == '') {
                // this.errorService.handleError(
                //     'Oops! You forgot to select a template for your video. Please select a template to proceed.',
                //     'Template Not Selected',
                //     true,
                //     true,
                // );
                this.IsTemplateError = true;
            } else if (this.lSelectedLocations.length == 0 && !this.AllLocaleSelected) {
                this.errorService.handleError(
                    'Oops! You forgot to select a location for your video. Please select a location to proceed.',
                    'Location Not Selected',
                    true,
                    true,
                );
            } else {
                this.errorService.handleError('Please select a video', 'Error', true, true);
            }
        });

        // this.openLocation();

        let vid = new video();

        vid.AllLocaleSelected = this.AllLocaleSelected;
        vid.locales = this.AllLocaleSelected ? [] : this.lSelectedLocations;

        // Extract images and clips from user media
        if (this.bDisplayMyMediaSection) {
            this.lUserSelectedVisuals as visual;
            this.lClipsList.push(this.lUserSelectedVisuals.visual_path);
        }
        if (!this.templateCategories || this.templateCategories.length == 0) {
            this.selectedTemplate = this.defaultTemplateName;
        }

    }
    ContentComponent_ReturnFileSize(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    removeSelectedVisuals(oIncommingVisual: any) {
        let nVisualIndex = this.lUserSelectedVisuals.indexOf(oIncommingVisual);
        this.lUserSelectedVisuals.splice(nVisualIndex, 1);
    }
    private back() {
        if (this.onBoardingManager.loggedUser.videoCount > 0) {
            this.oRoutes.navigate(['dashboard/myvideos']);
        } else {
            this.oRoutes.navigate(['/dashboard/first-time-user']);
        }
    }


    getFileNameName(fileName) {
        // console.log(fileName.substring(fileName.lastIndexOf('/') + 1));
        return fileName.substring(fileName.lastIndexOf('/') + 1);
    }
    getTitleFromFileName(fileName) {
        return fileName.split('.').slice(0, -1).join('.');
    }

    // CreateVideoOverviewComponent_DrawPagination(nIncommingVisualCount: number) {
    //   this.lPagination = [];
    //   this.nPageSize = Math.ceil(nIncommingVisualCount / 10);
    //   for (let nPaginationIndex = 1; nPaginationIndex <= this.nPageSize; nPaginationIndex++) {
    //     this.lPagination.push(nPaginationIndex);
    //   }

    // }

    // CreateVideoOverviewComponent_fetchNextPage(incommingPage) {
    //   if (incommingPage !== this.nCurrentPage) {
    //     this.nCurrentPage = incommingPage;

    //     let num_items = 10;
    //     let num_skip = (incommingPage - 1) * 10;
    //     this.accountManager.getLocale(this.sUserEmail, this.sSessionID, num_items, num_skip).then(res => {

    //       this.lLocales = res['results'];

    //       this.lLocales.forEach(ele => {
    //         if (this.AllLocaleSelected) {
    //           ele.selected = true;
    //         } else {
    //           this.lSelectedLocations.forEach(ee => {

    //             if (ele.id == ee.id) {
    //               ele.selected = true;
    //             }
    //           });
    //         }

    //       });

    //     });
    //   }
    // }

    close() {
        this.videoPopup = false;
    }
    previewVideo() {
        this.videoPreview.nativeElement.play();
        this.vidPreview = false;
    }
    displayVideoPopup() {
        this.videoPopup = true;
    }

    // modal media library
    visible = false;
    showMediaModal() {
        // console.log('Button ok clicked!');
        this.visible = true;
        this.bDisplayMyMediaSection = true;
    }
    handleMediaOk(): void {
        this.visible = true;
    }
    closeMediaModal(): void {
        this.visible = false;
    }
    mediaSelected(event) {
        this.lUserSelectedVisuals = event;
        // console.log(this.lUserSelectedVisuals);
        var result = this.lUserSelectedVisuals[0];
        console.log(this.lUserSelectedVisuals);

        this.vidTitle = this.getTitleFromFileName(this.getFileNameName(result.fpath));
        this.uploadedVdeo = {
            filename: result.name != undefined && result.name != '' ? result.name : result.fpath,
            fpath: result.visual_path,
            thumb: result.thumbnail_signed_url,
            url: result.signed_url,
        };
        this.getImageMetaData(result.thumbnail_signed_url);
    }

    displayLocationColumn(key ){
      let displayIt = false;
      this.defaultLocationFormField.map((field : any) =>{
        // console.log(field.name , key, field.display != undefined )
        if(field.name == key && field.display != undefined && field.display == 'true'){
          displayIt =  true;
        }
      });
      return displayIt;
    }
    getImageMetaData(src) {
        let img = new Image();
        let __this = this;
        img.onload = function () {
            __this.aspect_ratio = __this.getAspectRatio(img);
            __this.videoAspectRation = __this.aspect_ratio;
            // console.log(__this.getAspectRatio(img));
        };

        img.src = src;

        this.visible = false;
    }
    gcd(a, b): number {
        return b == 0 ? a : this.gcd(b, a % b);
    }
    aspect_ratio = '16x9';
    getAspectRatio(img): string {
        const w = img.naturalWidth;
        const h = img.naturalHeight;
        let r = this.gcd(w, h);
        return w / r + 'x' + h / r;
    }
    showPopUp(): void {
        // console.log('Button ok clicked!');
        this.isModalVisible = true;
    }
    hidePopUP(): void {
        // console.log('Button cancel clicked!');
        this.isModalVisible = false;
        //this.back();
        this.oRoutes.navigate(['my-videos/locationVideos']);
    }


    openFileUploadModal() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '100wv';
        dialogConfig.height = '100vh';
        dialogConfig.panelClass = 'pronto-dialog';
        dialogConfig.closeOnNavigation = true;

        dialogConfig.data = {
            uploadFor: 'video_restricted',
            multipleUpload: false,
            dropBoxText: '',
            heading: 'Upload a Video',
            allowDuplicate: true,
        };

        let dialogModal = this.dialog.open(UploadFileDialogComponent, dialogConfig);

        dialogModal.afterClosed().subscribe((data: any) => {
            if (data.status == 'success') {
                const uploadedFiles = data.result[0];
                this.oSessionService.oUploadedLogo = uploadedFiles;
                // this.isUploadVideo = true;
                this.vidTitle = this.getTitleFromFileName(uploadedFiles.name);
                this.uploadedVdeo = {
                    filename: uploadedFiles.name,
                    fpath: uploadedFiles.visual_path,
                    duration: uploadedFiles.duration.toFixed(2),
                    thumb: uploadedFiles.thumbnail,
                    url: uploadedFiles.url,
                    size: uploadedFiles.size,
                };

                this.getImageMetaData(uploadedFiles.thumbnail);

                // this.isUploadVideo = true;
                this.cd.detectChanges();
            } else {
            }
        });

        this.mediaManger.replaceMediaFromVisualPopup.pipe(takeUntil(this.destroy$)).subscribe((item) => {
            if (item && item.length > 0) {
                const uploadedFiles: any = item[0];

                this.oSessionService.oUploadedLogo = uploadedFiles;
                let fileNameReceieved = uploadedFiles.name ? uploadedFiles.name : uploadedFiles.visual_path;

                this.vidTitle = this.getTitleFromFileName(this.getFileNameName(fileNameReceieved));
                // this.isUploadVideo = true;
                this.uploadedVdeo = {
                    filename: this.vidTitle,
                    fpath: uploadedFiles.visual_path,
                    duration: uploadedFiles.duration.toFixed(2),
                    thumb: uploadedFiles.thumbnail_signed_url,
                    url: uploadedFiles.signed_url,
                    size: uploadedFiles.size,
                };
                this.getImageMetaData(uploadedFiles.thumbnail_signed_url);
                console.log(this.uploadedVdeo);
                this.cd.detectChanges();
            }
            this.mediaManger.showMediaReplacementPopup = false;
            this.cd.detectChanges();
        });
    }
    selectTemplate(temp) {

        this.selectedTemplate = temp.name;
        this.IsTemplateError = false;
    }
    vidProps(event) {
        // var video = document.getElementById("videoPreview");
        var video = <HTMLVideoElement>document.getElementById('videoPreview');
        var video_height = event.target.videoHeight;
        var video_width = event.targert.videoWidth;
        var videotag_width = event.target.offsetWidth;
        var videotag_height = event.target.offsetHeight;
    }
    CreateVideoOverviewComponent_SelectAspectRation(incommingAspectRatio) {
        this.sSelectedAspectRatio = incommingAspectRatio;
        this.videoAspectRation = incommingAspectRatio.ratio_value;
        this.videoAspectRation = this.aspect_ratio;
        // console.log('  this.sSelectedAspectRatio ', this.sSelectedAspectRatio);
        this.videoAspectRatio.map((item, i) => {
            item.isSelected = false;
            if (this.sSelectedAspectRatio == item) {
                item.isSelected = true;
                // this.videoAspectRatio[i].isSelected = true;
                this.videoAspectRatio[i] = item;
            }
        });
    }
    selectedLocale(event){

        this.AllLocaleSelected = event.AllLocaleSelected;
        if(this.AllLocaleSelected == true){
            this.lSelectedLocations = []
        }
        else{
            this.lSelectedLocations = event.lSelectedLocations;
        }

    }
    returnFileName(filename) {
        var split = filename.split('.');
        var filename = split[0];
        var extension = split[1];
        if (filename.length > 15) {
            filename = filename.substring(0, 15);
        }
        var result = filename + '.' + extension;
        return result;
    }
    EditCustomFPS(index){
        if(index == 6){
            this.enableCustomFpsInput = true;
            this.lVideoFps[6].isEnabled = false;
            if(this.customFpsValue != ''){
                    this.fpsInput.nativeElement.value = parseFloat(this.customFpsValue); 
                }
        }
        else{
            this.enableCustomFpsInput = false;
            this.lVideoFps[6].isEnabled = true;
            this.lVideoFps[6].checked = false;
            this.isClassAdded = false;

        }
    }
    updateCustomFPSValue(event: KeyboardEvent) {
        const inputElement = event.target as HTMLInputElement; 
        const val = document.getElementById('fpsInput') as HTMLInputElement;
        if (event.key === '-' || event.key === 'Subtract') {
            event.preventDefault();
          }
        this.customFpsValue = inputElement.value;
        if (event.key === 'Enter' && this.customFpsValue != '') {
        this.lVideoFps[6].value = parseFloat(this.customFpsValue);
        this.lVideoFps[6].name = this.customFpsValue;
        this.lVideoFps[6].checked = true;
        this.lVideoFps[6].isEnabled = true;
        console.log('Custom FPS value updated:', this.lVideoFps[6].value);
        this.selectedFps = parseFloat(this.customFpsValue);
        // this.fOverviewForm.controls["fps"].setValue(parseFloat(this.customFpsValue));
        this.enableCustomFpsInput = false;
        this.isClassAdded = true;
            
    }
}
}
