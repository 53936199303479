import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MediaManagerService } from "./../../../Managers/media-manager.service";
import { VideoManagerService } from "./../../../Managers/video-manager.service";
import { cDataService } from "./../../../services/data.service";
import { cSessionService } from "./../../../services/session.service";
import { cTemplateConfigService } from "./../../../services/template.service";
import { cWebSocketService } from "./../../../services/websocket.service";
import { cVideoDetails } from "./../../../shared/models/video-details";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { ErrorService } from "./../../../services/error.service";
import { OnboardingManagerService } from "src/app/Managers/onboarding-manager.service";
import { AccountManagerService } from "src/app/Managers/account-manager.service";
import { SubscriptionplanManagerService } from "src/app/Managers/subscriptionplan-manager.service";
import { VideoService } from "src/app/services/dataServices/video.service";
import { environment } from "src/environments/environment";
import Hls from "hls.js";


@Component({
  selector: "app-dashboard-overview",
  templateUrl: "./dashboard-overview.component.html",
  styleUrls: ["./dashboard-overview.component.scss"],
})
export class DashboardOverviewComponent implements OnInit, OnDestroy {
  @ViewChild('videoPreview') videoPreview: ElementRef;
  private hlsUrl = 'http://sample.vodobox.net/skate_phantom_flex_4k/skate_phantom_flex_4k.m3u8'; 
  private hls: Hls | null = null; // HLS instance
  sAddContentSlide: string;
  oVideoDetails: any;
  lKeyterms: any = [];
  sUserEmail: string;
  sSessionId: string;
  sVideoId: string;
  bLoading: boolean = false;
  sErrorMessage: string;
  bDisplayErrorMsg: boolean = false;
  lTemplateCategory: any = [];
  lTemplateNames: any = [];
  oSetTimeOut: any;
  lVideoTimeout: any = [];
  nVideoDuration: number;
  nVideoTimeOut: any;
  oClosePopup: any = 0;
  showHeaderMenu = true;
  sPublicVideoUrl: string;
  copiedText = "";
  louderOrg = false;
  canPlay = false;


  constructor(
    private oWebSocketService: cWebSocketService,
    public videoManager: VideoManagerService,
    private oDataService: cDataService,
    private oSessionService: cSessionService,
    private oRouter: Router,
    private oTemplateConfigService: cTemplateConfigService,
    public errorService: ErrorService,
    public onboardingManager: OnboardingManagerService,
    public accountManager: AccountManagerService,
    public subscriptionPlan: SubscriptionplanManagerService,
    public videoService : VideoService
  ) { }

  ngOnInit(): void { 
    if( this.onboardingManager.profile.organization_id == environment.LouderId || this.onboardingManager.profile.organization_id == environment.client_id){
    this.louderOrg = true;
     }
    if ((this.onboardingManager.profile?.user_status) && this.onboardingManager.profile.user_status == "confirmed_not_subscribed") {
      this.showHeaderMenu = false;
    }

    this.sPublicVideoUrl = this.videoManager?.selectedVideo?.public_url;
    let chkvid = this.videoManager?.selectedVideo?.scheduleVideos;

    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.sVideoId = this.oSessionService.SessionService_GetVideoId();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();


    if (this.videoManager.selectedVideo?.schedule) {
      this.videoManager
        .getSchedulesVideos(
          this.sUserEmail,
          this.sSessionId,
          this.videoManager.selectedVideo.schedule["schedule_id"]
        )
        .then((res) => {
          this.videoManager.selectedVideo.scheduleVideos = res["video_list"];
        })
        .catch((err) => {
          this.errorService.handleError(err.errorMessage, err.errorType, true);
        });
    }
    if(this.videoManager.selectedVideo?.key_terms){
      this.lKeyterms = this.videoManager.selectedVideo.key_terms;
    }

    console.log(" into video page ", this.videoManager.selectedVideo);
    this.videoService.checkVideoStatus(this.sUserEmail, this.sSessionId, this.videoManager.selectedVideo?.fpath, [this.videoManager.selectedVideo]);


  }

  ngOnDestroy(): void {
    // Destroy the HLS player
    if (this.hls) {
      this.hls.destroy();
      this.hls = null;
    }
  }
  DashboardOverviewComponent_PlayVideo() {
    this.videoPreview.nativeElement.play();
    // this.getStreamVideo();

    // if (Hls.isSupported()) {
    //     console.log("hls supported");
    //   this.hls = new Hls();
    //   this.hls.loadSource(this.hlsUrl);
    //   this.hls.attachMedia(video);
    // } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    //   video.src = this.hlsUrl;
    //   video.load();
    // }
  
  }

  DashboardHeaderComponent_GetClickLocation(event) {
    this.oClosePopup = 0;
    if (event.clientX <= 65) {
      this.oClosePopup = 1;
      setTimeout(() => {
        this.oClosePopup = 0;
      }, 200);
    }
  }
  // modal scheduled video
  isVisible = false;
  showModal(): void {
    this.isVisible = true;
  }
  handleOk(): void {
    console.log("Button ok clicked!");
    this.isVisible = true;
  }
  handleCancel(): void {
    console.log("Button cancel clicked!");
    this.isVisible = false;
  }

  checktag() {
    console.log(this.videoManager.selectedVideo?.key_terms, "checktag");
  }

  ngAfterViewInit(): void {
    this.videoManager.getsignedThumbandURL(this.sUserEmail, this.sSessionId, [this.videoManager.selectedVideo]);
    setTimeout(() => {
      const video = document.querySelector("video");
      if (video) {
        video.onplay = (e) => {
          // console.log(
          //   "The Boolean paused property is now false. Either the " +
          //   "play() method was called or the autoplay attribute was toggled."
          // );
        };
        video.onpause = (e) => {
          // console.log(
          //   "The Boolean paused property is now false. Either the " +
          //   "play() method was called or the autoplay attribute was toggled."
          // );
        };
      }
    }, 2000);
  }
  TimelineViewComponent_CopyToClipBoard() {
    this.copiedText = this.sPublicVideoUrl;
    navigator.clipboard.writeText(encodeURI(this.sPublicVideoUrl));
    console.log(encodeURI(this.sPublicVideoUrl));
  }
  goToSubscribe() {
    this.subscriptionPlan.showPlanDialogue();
  }
  getStreamVideo() { 
    console.log("video streaming called");
    
    this.videoService.getVideoStream(this.sUserEmail, this.sSessionId, this.sVideoId).subscribe({
      next: (videoBlob) => {
        const videoURL = URL.createObjectURL(videoBlob); // Create an object URL for the Blob
        const videoElement = <HTMLVideoElement>document.getElementById("videoPreview");
        console.log("url stream",videoURL);
        
        videoElement.src = videoURL; // Set the video source
        videoElement.load();         // Load the new video
      videoElement.addEventListener('canplay', () => {
        console.log('Video is ready to play');
        this.canPlay = true;
        videoElement.play(); 
      });
      },
      error: (error) => {
        console.error('Error streaming video:', error);
      }
    });
  }
}
