import { ProntoSettingService } from './../../../services/utils/pronto-setting.service';
import { LocationStrategy } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { cDataService } from './../../../services/data.service';
import { cSessionService } from './../../../services/session.service';
import { cTemplateConfigService } from './../../../services/template.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { CreateVideoManagerService } from '../../../Managers/create-video-manager.service';
import { VideoManagerService } from '../../../Managers/video-manager.service';
import { Subscription } from 'rxjs';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { ErrorService } from 'src/app/services/error.service';
import { VideoService } from 'src/app/services/dataServices/video.service';
import { VideoDataStore } from 'src/app/stores/video.store';
declare var $: any;
@Component({
    selector: 'app-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnInit, OnDestroy {
    @ViewChild('videoPreview') videoPreview: ElementRef;
    bFullHeader: boolean = false;
    sUserEmail: string;
    sSessionId: string;
    sVideoTitle: string;
    sVideoDuration: string;
    sVideoId: string;
    sErrorMessage: any;
    sErrorMessageTitle: any;
    bDisplayErrorMsg: boolean = false;
    bLoading: boolean = false;
    sVideoURL: string;
    sThumbnail: string;
    playVideo: boolean = false;
    oGeneratedVideo: any = { url: '', thumb: '' };
    lVideoTimeout: any = [];
    nVideoDuration: number;
    nVideoTimeOut: any;
    oTimeOut: any;
    oPublicURL: any;
    bResponse: boolean = false;
    bDisplayEditVideoLoader: boolean = false;
    oClosePopup: any = 0;
    hideButton = false;
    videoPreviewPlay = false;
    canPlay = false;

    constructor(
        private oWebSocketService: cWebSocketService,
        private oSessionService: cSessionService,
        private errorService: ErrorService,
        public videoManger: CreateVideoManagerService,
        public videoListManger: VideoManagerService,
        private oDataService: cDataService,
        private oRouter: Router,
        private oTemplateConfigService: cTemplateConfigService,
        public dataservice: VideoService,
        private location: LocationStrategy,
        private prontoSettingService: ProntoSettingService,
        public videoStore: VideoDataStore,
    ) {
        console.log("window.history ", window);
        history.pushState(null, null, window.location.href);
        this.location.onPopState(() => {
            console.log(" isVideoGenerated ", this.videoManger.isVideoGenerated, this.videoManger.selectedVideo);

            if (!this.videoManger.isVideoGenerated) {
                history.pushState(null, null, window.location.href);
                this.prontoSettingService.emitBackButtonDialogTextSubject({
                    visible: true,
                    text: 'Your work hasn`t been saved yet. If you leave now, any unsaved progress will be lost.',
                });
            }
        });
        if (this.oRouter.url === '/edit-video/timeline-view') {
            this.bFullHeader = true;
            // history.pushState(null, null, window.location.href);
            // check if back or forward button is pressed.
        }
    }
    sub: Subscription;

    loadPlayerEvents() {
        const __this = this;
        console.log(' video trigger ');
        setTimeout(() => {
            const video = document.querySelector('video');
            console.log(' --- view of video ...');
            if (video) {
                video.onplay = (e) => {
                    __this.hideButton = true;
                    // console.log(
                    //     'The Boolean paused property is now false. Either the ' +
                    //     'play() method was called or the autoplay attribute was toggled.',
                    // );
                };
                video.onpause = (e) => {
                    __this.hideButton = true;
                    // console.log(
                    //     'The Boolean paused property is now false. Either the ' +
                    //     'play() method was called or the autoplay attribute was toggled.',
                    // );
                };
            }
        }, 1000);
    }
    ngOnInit(): void {
        // this.videoManger.isEditMode = true;
        this.sub = this.videoManger.submitForm.subscribe((c) => {
            this.videoListManger.selectedVideo = this.videoManger.selectedVideo;
            this.oRouter.navigateByUrl('/my-videos/draftsVideo');
        });
        this.videoManger.previewMode = true;
        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

        this.bLoading = true;
        this.videoManger
            .generateVideo(this.sUserEmail, this.sSessionId, this.videoManger.selectedVideo.fpath)
            .then((res) => {
                this.videoManger
                    .generateVideoStatus(this.sUserEmail, this.sSessionId, this.videoManger.gv_task_id)
                    .then((rr) => {
                        // this.videoManger.isEditMode = true;
                        let batchUrl = this.videoManger.selectedVideo.fpath;
                        this.dataservice.getThumbBatchSignedURL(this.sUserEmail, this.sSessionId, [batchUrl]).then((res) => {
                            let output = res['output'];
                            this.videoManger.selectedVideo.thumbnail = output[0].thumb_signed_url;

                            this.videoListManger.selectedVideo = this.videoManger.selectedVideo;

                            this.bLoading = false;
                            this.bResponse = true;
                            this.loadPlayerEvents();
                        });
                    })
                    .catch((error) => {
                        if (error.errorMessage.includes("Our AI couldn't find relevant clips")) {
                            this.sErrorMessage = error.errorMessage;
                        }
                        else {
                            this.sErrorMessage = `We're sorry, but we were unable to generate your video due to technical issues. Please try again later or contact our support team for assistance.`;
                        }
                        this.sErrorMessageTitle = 'Video Generation Failed';
                        this.errorService.handleError(this.sErrorMessage, this.sErrorMessageTitle, true, false, true, false, { showContactSupportOnLeftSide: true });
                        this.bLoading = false;
                    });
            })
            .catch((err) => {
                this.bLoading = false;
            });
    }
    PreviewComponent_SettingVideoTimeout() {
        this.nVideoDuration = this.oSessionService.SessionService_GetVideoDuration();
        if (this.lVideoTimeout) {
            this.nVideoTimeOut = this.lVideoTimeout.find(({ duration }) => duration === this.nVideoDuration);
        } else {
            this.oSessionService.SessionService_SetVideoTimeout();
            this.lVideoTimeout = this.oSessionService.SessionService_GetVideoTimeout();
            this.nVideoTimeOut = this.lVideoTimeout.find(({ duration }) => duration === this.nVideoDuration);
        }
    }
    PreviewComponent_PlayVideo() {
        this.videoPreview.nativeElement.play();
        // this.getStreamVideo();
        this.videoPreviewPlay = true;
    }

    publishVideo() {
        this.videoManger
            .updateVideoStatus(this.sUserEmail, this.sSessionId, this.videoManger.selectedVideo.fpath, 'published')
            .then((res) => {
                this.videoListManger.selectedVideo = this.videoManger.selectedVideo;
                this.videoListManger.selectedVideo.public_url = res['public_url'];
                this.oRouter.navigate(['/published-video/timeline-view']);
            })
            .catch((err) => {
                if (err.errorMessage.includes('monthly quota')) {
                    this.errorService.handleError(err.errorMessage, 'Monthly Quota Exceeded', true, false, true);
                }
                else {
                    if (err.errorMessage.includes("Our AI couldn't find relevant clips")) {
                        this.sErrorMessage = err.errorMessage;
                    }
                    else {
                        this.sErrorMessage = `We're sorry, but we were unable to generate your video due to technical issues. Please try again later or contact our support team for assistance.`;
                    }
                       this.sErrorMessageTitle = 'Video Generation Failed';
                    this.bDisplayErrorMsg = true;
                }



            });
    }
    PreviewComponent_PublishVideo() {
        let oPublishVideoRequest = {
            session_id: this.sSessionId,
            email: this.sUserEmail,
            video_id: this.sVideoId,
            publish_status: 'published',
        };
        this.oWebSocketService.WebSocket_RegisterEvent(
            'update_video_publish_status_result',
            this.PreviewComponent_HandlePublishVideoResponse,
        );
        console.log(' PreviewComponent_HandlePublishVideoResponse : Publish Video Request to WebSocket ==> ', oPublishVideoRequest);
        this.oWebSocketService.WebSocket_SendMessage('update_video_publish_status', JSON.stringify(oPublishVideoRequest));
    }
    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.videoManger.previewMode = false;
        this.videoManger.isEditMode = true;
        if (this.oTimeOut) clearTimeout(this.oTimeOut);

        this.prontoSettingService.emitBackButtonDialogTextSubject({
            visible: false,
            text: '',
        });
    }
    PreviewComponent_HandlePublishVideoResponse = (InComingMessage) => {
        console.log('PreviewComponent_HandlePublishVideoResponse : ==> ', InComingMessage);
        let oFormatedIncommingMessage = JSON.parse(InComingMessage);
        this.oPublicURL = oFormatedIncommingMessage.public_url;
        this.oSessionService.cSessionService_SetPublicURL(this.oPublicURL);
        console.log('The public URL is====>>>', this.oPublicURL);

        if (oFormatedIncommingMessage['errorMessage']) {
            // this.sErrorMessage = 'An error occurred while publishing video. Please try again.';
            this.sErrorMessage = `We're sorry, but we were unable to generate your video due to technical issues. Please try again later or contact our support team for assistance.`;
            this.sErrorMessageTitle = 'Video Generation Failed';
            this.bDisplayErrorMsg = true;
            this.bLoading = false;
        } else {
            this.oTemplateConfigService.bSendGenerateVideoRequest.next(0);
            this.oRouter.navigate(['/published-video/timeline-view']);
        }
    };
    PreviewComponent_SendUserVideoRequest() {
        this.bLoading = true;
        let oOtherSettingForm = this.oSessionService.cSessionService_GetGenerateVideoPayload();
        this.oWebSocketService.WebSocket_SendMessage('user_video_request', JSON.stringify(oOtherSettingForm));
    }

    PreviewComponent_HandleCreateVideoResponse = (InComingMessage) => {
        if (JSON.parse(InComingMessage)['errorMessage']) {
            console.error('PreviewComponent_HandleCreateVideoResponse : Error ==> ', InComingMessage);
            // this.sErrorMessage = 'An error occurred while creating video. Please try again.';
            this.sErrorMessage = `We're sorry, but we were unable to generate your video due to technical issues. Please try again later or contact our support team for assistance.`;
            this.sErrorMessageTitle = 'Video Generation Failed';
            $('#error-popup').modal('show');
            this.bDisplayErrorMsg = true;
            setTimeout(() => {
                this.bDisplayErrorMsg = false;
            }, 3000);
        } else {
            console.log('PreviewComponent_HandleCreateVideoResponse : Response ===>>>', JSON.parse(InComingMessage));
            this.PreviewComponent_GenerateUserVideoRequest();
        }
    };
    PreviewComponent_GenerateUserVideoRequest() {
        this.bLoading = true;
        this.oTimeOut = setTimeout(() => {
            this.bLoading = false;
            this.sErrorMessageTitle = 'Video Generation Failed';
            this.sErrorMessage =
                'Pronto is experiencing network issues right now. We’re working on fixing this as soon as possible. Please refresh or try again later.';
            this.bDisplayErrorMsg = true;
        }, this.nVideoTimeOut.timeout);

        let oGenerateVideoRequest = {
            session_id: this.sSessionId,
            email: this.sUserEmail,
            video_id: this.sVideoId,
        };
        this.oWebSocketService.WebSocket_RegisterEvent('generate_user_video_result', this.PreviewComponent_GenerateUserVideoResponse);
        console.log(' PreviewComponent_GenerateUserVideoResponse : Generate Video Request to WebSocket=>', oGenerateVideoRequest);
        this.oWebSocketService.WebSocket_SendMessage('generate_user_video_request', JSON.stringify(oGenerateVideoRequest));
    }
    PreviewComponent_GenerateUserVideoResponse = async (InComingMessage) => {
        console.log('PreviewComponent_GenerateUserVideoResponse : ==> ', InComingMessage);
        let oFormatedIncommingMessage = JSON.parse(InComingMessage);
        clearTimeout(this.oTimeOut);
        if (oFormatedIncommingMessage['errorMessage']) {
            this.sErrorMessageTitle = 'Video Generation Failed';
            this.sErrorMessage = 'An error occurred while creating video. Please try again.';
            this.bDisplayErrorMsg = true;
            this.bResponse = true;
            setTimeout(() => {
                this.bLoading = false;
            }, 2000);
        } else {
            //get signed url of video
            this.oGeneratedVideo.url = await this.oDataService.DataService_GetSignedURLForDownload(oFormatedIncommingMessage.video_id);
            //get signed url of thumbnail
            this.oGeneratedVideo.thumb = await this.oDataService.DataService_GetSignedURLForDownload(oFormatedIncommingMessage.thumb);
            this.bResponse = true;
            this.bDisplayErrorMsg = false;
            setTimeout(() => {
                this.bLoading = false;
            }, 2000);
            this.oSessionService.cSessionService_SetGeneratedVideo(this.oGeneratedVideo);
        }
    };
    PreviewComponent_NavigateToDashboard() {
        this.oSessionService.SessionService_RemoveCreateVideoSettings();
        this.oRouter.navigateByUrl('/dashboard/myvideos');
    }
    PreviewComponent_SaveVideo() {
        this.oSessionService.SessionService_RemoveCreateVideoSettings();
        this.oSessionService.cSessionService_SetUserVideosCount(1);
        this.oRouter.navigateByUrl('/my-videos/draftsVideo');
    }
    PreviewComponent_HideLoader(bEmittedLoadingController: any) {
        this.oTimeOut = setTimeout(() => {
            this.bDisplayEditVideoLoader = false;
            this.sErrorMessageTitle = 'Video Generation Failed';
            this.sErrorMessage =
                'Pronto is experiencing network issues right now. We’re working on fixing this as soon as possible. Please refresh or try again later.';
            this.bDisplayErrorMsg = true;
        }, this.nVideoTimeOut.timeout);
        this.bDisplayEditVideoLoader = bEmittedLoadingController;
    }
    PreviewComponent_GetClickLocation(event) {
        this.oClosePopup = 0;
        if (event.clientX <= 65) {
            this.oClosePopup = 1;
            setTimeout(() => {
                this.oClosePopup = 0;
            }, 200);
        }
    }
    // modal scheduled video
    isVisible = false;
    showModal(): void {
        this.isVisible = true;
    }
    handleOk(): void {
        this.isVisible = true;
    }
    handleCancel(): void {
        this.isVisible = false;
    }
    visibleLocation = false;
    placementLocation: NzDrawerPlacement = 'right';
    openLocation(): void {
        this.visibleLocation = true;
    }
    closeLocation(): void {
        this.visibleLocation = false;
    }

  getStreamVideo() { 
    console.log("video streaming called");
    
    this.dataservice.getVideoStream(this.sUserEmail, this.sSessionId, this.videoManger.selectedVideo?.fpath).subscribe({
      next: (videoBlob) => {
        const videoURL = URL.createObjectURL(videoBlob); // Create an object URL for the Blob
        const videoElement = <HTMLVideoElement>document.getElementById("videoPreview");
        console.log("url stream",videoURL);
        
        videoElement.src = videoURL; // Set the video source
        videoElement.load();         // Load the new video
      videoElement.addEventListener('canplay', () => {
        console.log('Video is ready to play');
        this.canPlay = true;
        videoElement.play(); 
      });
      },
      error: (error) => {
        console.error('Error streaming video:', error);
      }
    });
  }
}
