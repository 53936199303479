import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexYAxis,
  ApexLegend,
  ApexXAxis,
  ApexTooltip,
  ApexTheme,
  ApexGrid
} from 'ng-apexcharts';
import { ErrorService } from 'src/app/services/error.service';
import { CreateVideoManagerService } from './../../Managers/create-video-manager.service';
import { MediaManagerService } from './../../Managers/media-manager.service';
import { mediaFile, visual } from './../../models/media';
import { video } from './../../models/video';
import { cDataService } from './../../services/data.service';
import { cSessionService } from './../../services/session.service';
import { cTemplateConfigService } from './../../services/template.service';
import { VideoService } from 'src/app/services/dataServices/video.service';
export type socialMediaChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: any;
  theme: ApexTheme;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  colors: string[];
  markers: any;
  grid: ApexGrid;
  video: video;
};
@Component({
  selector: 'app-timeline-view-sidepanels',
  templateUrl: './timeline-view-sidepanels.component.html',
  styleUrls: ['./timeline-view-sidepanels.component.scss']
})
export class TimelineViewSidepanelsComponent implements OnInit, OnDestroy {
  prontoAssets: any = ['item', 'item', 'item',]
  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild("chart") chart2: ChartComponent = Object.create(null);
  @ViewChild('myVideo') myVideo: ElementRef;
  @ViewChild('myVideoO') myVideoO: ElementRef;
  @ViewChild('AddInputTagElement') AddInputTagElement: ElementRef;
  public socialMediaChartOptions: Partial<socialMediaChartOptions>;
  fAddressForm: FormGroup;
  fLooknFeelForm: FormGroup;
  oSavedFormData: any;
  bURLToggle: boolean = true;
  bAddressToggle: boolean = true;
  bPhoneToggle: boolean;
  sSessionID: string;
  sUserEmail: string;
  bRevert: boolean = true;
  bRevertSettings: boolean = true;
  // sEditVideoId: any;
  oVideo: any;
  oUploadedLogo: any;
  nUploadedLogoSize: number = 0;
  sUploadedLogoName: any;
  sDisplayResponseErrorMessage: any;
  bDisplayErrorMessage: boolean;
  oPrimaryColor: any;
  oFontColor: any;
  oLooknFeelForm: any;
  lKeyTermsTags: any;
  sTemplateChange: any;
  lKey_terms: any = [];
  videoThumbUrl: any;
  videoThumb: string;
  sVideoId: string;
  videoUrl: string;
  lTemplateCategory: any = [];
  nSelectedTemplateIndex: any;
  oSelectedTemplate: any;
  lChangedTemplate: any = [];
  bDisplayAddInputField: boolean = false;
  inputValue: string = "";
  bDisplayUploadLogoSection: boolean = true;
  oLogoDetails: any;
  canPlay = false;
  session: cSessionService;
  @Input() overviewSidebar: boolean;
  @Input() totalSelectedLocals: any;

  @Output() onOpen = new EventEmitter<any>();
  showLogoUploadModal = false;
  thumb = true;
  constructor(private oFormBuilder: FormBuilder,
    private errorService: ErrorService, public dataservice: VideoService, 
    private sSessionService: cSessionService, private oDataService: cDataService, private sTemplateService: cTemplateConfigService, public videoManager: CreateVideoManagerService, private mediaManager: MediaManagerService) {
    this.sSessionID = this.sSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.sSessionService.cSessionService_GetUserEmail();
    //this.sVideoId = this.sSessionService.SessionService_GetVideoId();
    // this.sEditVideoId = this.sSessionService.SessionService_GetEditVideoId();
    // console.log("edit video Id is================>>>>>", this.sEditVideoId);

    this.session = sSessionService;

    this.socialMediaChartOptions = {
      series: [
        {
          name: 'Youtube',
          data: [30, 60, 20, 280, 140, 115, 350, 370, 250, 105, 200,]
        },
        {
          name: 'Facebook',
          data: [80, 140, 80, 100, 190, 205, 250, 170, 50, 205, 180,]
        },
        {
          name: 'Instagram',
          data: [10, 50, 30, 260, 120, 105, 150, 190, 210, 115, 150,]
        }
      ],
      chart: {
        fontFamily: 'Montserrat,sans-serif',
        height: 300,
        type: 'line',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 3,
      },
      stroke: {
        curve: 'straight',
        width: '3',
      },
      colors: ['#FF0000', '#3b5998', '#ca40cb'],
      legend: {
        show: false,
      },

      grid: {
        show: true,
        strokeDashArray: 0,
        borderColor: 'rgba(0,0,0,0.1)',
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      xaxis: {
        type: 'category',
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        labels: {
          style: {
            colors: '#a1aab2'
          }
        }
      },
      yaxis: {

        labels: {
          style: {
            colors: '#a1aab2'
          }
        }
      },
      tooltip: {
        theme: 'dark'
      },

    };
  }

  ngOnInit(): void {

    // this.InitializeJqueryCode();
    this.oVideo = this.videoManager.selectedVideo;
    this.oSelectedTemplate = this.oVideo?.template_category;
    // console.log("the selected template Category is=====>>>>",this.oSelectedTemplate);

    // this.TimelineViewSidePanelsComponent_GetVideoPathDetails();
    //this.lTemplateCategory = this.sTemplateService.TemplateConfigService_FetchTemplateCategoriesNames();
    // this.oSavedFormData = this.sSessionService.cSessionService_GetOtherSettingForm();
    // this.oLooknFeelForm = this.sSessionService.cSessionService_GetLooknFeelForm();
    // this.TimelineViewSidePanelsComponent_SetAddressForm();
    //this.TimelineViewSidePanelsComponent_SetVideoForm();
    // this.videoThumbUrl = this.sSessionService.cSessionService_GetOriginalVideoThumbUrl();
    // this.bDisplayUploadLogoSection   = this.oLooknFeelForm.bDisplayUploadLogoSection;
    // this.oLogoDetails = this.sSessionService.cSessionService_GetUserLogo();
    // console.log("logo details : ==>",this.oLogoDetails)

    this.lKey_terms = this.videoManager.selectedVideo.key_terms;
  }
  // async TimelineViewSidePanelsComponent_GetVideoPathDetails()
  // {
  //   //to get video Id or fpath of the original video
  //   //to generate video thumb that we use in next step to get thumburl for display
  //   this.videoThumb = await this.oDataService.DataService_GetUserVisualThumbnail(this.sEditVideoId);
  //   //to get thumbURL for display
  //   this.videoThumbUrl = await this.oDataService.DataService_GetSignedURLForDownload(this.videoThumb);
  //   //getting video url for display
  //   this.videoUrl = await this.oDataService.DataService_GetSignedURLForDownload(this.sEditVideoId);
  // }
  TimelineViewSidePanelsComponent_ToggleLogoSection(event: any) {
    let value = event.target.value;
    if (value === "upload-logo") {
      this.bDisplayUploadLogoSection = true;
    }
    else {
      this.bDisplayUploadLogoSection = false;
    }
    //  this.fLooknFeelForm.patchValue({bDisplayUploadLogoSection : this.bDisplayUploadLogoSection});
  }
  async TimelineViewSidePanelsComponent_SelectTemplateCategory(select, index) {
    let oName: any;
    this.nSelectedTemplateIndex = index;
    this.oSelectedTemplate = select;
    await this.sTemplateService.TemplateConfigService_FetchTemplateCategories(this.oSelectedTemplate).then(result => {
      this.lChangedTemplate = result;
      if (this.oSelectedTemplate == "Advert" || this.oSelectedTemplate == "Product" || this.oSelectedTemplate == "Candid") {
        oName = this.lChangedTemplate[0];
      }
      else {
        oName = this.lChangedTemplate[index];
      }
      console.log("the Index Value======>>>>", oName);

    });
    //this.fLooknFeelForm.patchValue({template_category: this.oSelectedTemplate, template_name: oName.sName});
    this.sSessionService.cSessionService_SetLooknFeelForm(this.fLooknFeelForm.value);

  }
  TimelineViewSidePanelsComponent_SetAddressForm() {
    this.fAddressForm = this.oFormBuilder.group(
      {
        url: [this.oSavedFormData.url],
        address: this.oFormBuilder.group
          ({
            line1: [this.oSavedFormData.address.line1],
            line2: [this.oSavedFormData.address.line2],
            city: [this.oSavedFormData.address.city],
            state: [this.oSavedFormData.address.state],
            postal_code: [this.oSavedFormData.address.postal_code],
            country: [this.oSavedFormData.address.country],
          }),
        phone: [this.oSavedFormData.phone],
        bDisplayUrl: this.oSavedFormData.bDisplayUrl,
        bDisplayAddress: this.oSavedFormData.bDisplayAddress,
        bDisplayPhone: this.oSavedFormData.bDisplayPhone,
      });
    this.bURLToggle = this.oSavedFormData.bDisplayUrl;
    this.bAddressToggle = this.oSavedFormData.bDisplayAddress;
    this.bPhoneToggle = this.oSavedFormData.bDisplayPhone;
  }
  TimelineViewSidepanelsComponent_SendSaveAddressFormRequest() {
    this.bRevert = false;
    let oAddressSidePanelForm =
    {
      // video_id: this.sEditVideoId,
      video_id: this.sVideoId,
      session_id: this.sSessionID,
      email: this.sUserEmail,
      url: this.fAddressForm.value.url,
      address:
        ({
          line1: this.fAddressForm.value.address.line1,
          line2: this.fAddressForm.value.address.line2,
          city: this.fAddressForm.value.address.city,
          state: this.fAddressForm.value.address.state,
          postal_code: this.fAddressForm.value.address.postal_code,
          country: this.fAddressForm.value.address.country,
        }),
      phone: this.fAddressForm.value.phone,
      bDisplayAddress: this.bAddressToggle,
      bDisplayPhone: this.bPhoneToggle,
      bDisplayUrl: this.bURLToggle
    }
    console.log("The form Values are=====>>>", oAddressSidePanelForm);
    this.sSessionService.cSessionService_SetOtherSettingForm(oAddressSidePanelForm);
  }
  TimelineViewSidepanelsComponent_RevertToOriginalForm() {
    this.fAddressForm.reset();
    this.TimelineViewSidePanelsComponent_SetAddressForm();
  }
  TimelineViewSidePanelsComponent_PhoneToggle(event: any) {
    this.bPhoneToggle = event.target.checked;
    this.fAddressForm.patchValue({ bDisplayPhone: this.bPhoneToggle });
  }
  TimelineViewSidePanelsComponent_AddressToggle(event: any) {
    this.bAddressToggle = event.target.checked;
    this.fAddressForm.patchValue({ bDisplayAddress: this.bAddressToggle });
  }
  TimelineViewSidePanelsComponent_URLToggle(event: any) {
    this.bURLToggle = event.target.checked;
    this.fAddressForm.patchValue({ bDisplayUrl: this.bURLToggle });
  }

  TimelineViewSidePanelsComponent_SetVideoForm() {
    this.fLooknFeelForm = this.oFormBuilder.group(
      {
        session_id: this.sSessionID,
        email: this.sUserEmail,
        video_id: this.sVideoId,
        template_category: [this.oLooknFeelForm.template_category, [Validators.required]],
        key_terms: [this.oLooknFeelForm.key_terms],
        template_name: [this.oLooknFeelForm.template_name],
        color: [this.oLooknFeelForm.color],
        font_color: [this.oLooknFeelForm.font_color],
        oUploadedLogo: [this.oLooknFeelForm.oUploadedLogo],
        nUploadedLogoSize: [this.oLooknFeelForm.nUploadedLogoSize],
        bDisplayUploadLogoSection: [this.oSavedFormData.bDisplayUploadLogoSection]

      });
    this.lKey_terms = this.oLooknFeelForm.key_terms
    this.oPrimaryColor = this.oLooknFeelForm.color;
    this.oFontColor = this.oLooknFeelForm.font_color;
    this.sTemplateChange = this.oLooknFeelForm.template_category;
    this.oUploadedLogo = this.oLooknFeelForm.oUploadedLogo;
    this.nUploadedLogoSize = this.oLooknFeelForm.nUploadedLogoSize;
    this.bDisplayUploadLogoSection = this.oSavedFormData.bDisplayUploadLogoSection;
    if (this.oLooknFeelForm.oUploadedLogo) {
      this.sUploadedLogoName = this.oLooknFeelForm.oUploadedLogo.filename;
    }
  }
  TimelineViewSidePanelComponent_PlayVideo() {
    this.thumb = false;
    this.myVideoO.nativeElement.play();
    // this.getStreamVideo();
    // this.myVideo.nativeElement.play();
  }
  TimelineViewSidePanelComponent_PauseVideoOnClose() {
    // this.myVideo.nativeElement.pause();
    this.myVideoO.nativeElement.pause();
    // const videoElement = <HTMLVideoElement>document.getElementById("myVideoO");
    // videoElement.pause();
  }
  TimelineViewSidePanelComponent_ReturnFileSize(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  startLogoUploadProcess($event, section = 'main') {
    console.log($event);
    if (section == 'dragdrop') {
      this.uploadLogoFile($event);
    } else {
      this.uploadLogoFile($event.target.files);
    }
  }

  async uploadLogoFile(files) {
    let file = files[0];
    if (file.type && !(file.type.indexOf('jpeg') > -1 || file.type.indexOf('jpg') > -1 || file.type.indexOf('png') > -1)) {
      this.errorService.handleError('Only jpeg, jpeg and png files are allowed', 'Logo Upload Failed', true, false, true);
    } else {
      this.showLogoUploadModal = true;
      this.nUploadedLogoSize = file.size;
      console.log("TimelineViewSidePanelsComponent_UploadFile : File To Upload ==> ", file);
      let media = new mediaFile();
      media.file = file;
      media.name = file.name;
      this.closeModal.nativeElement.click();
      this.mediaManager.uploadMediaDefaultLibrary(this.sUserEmail, this.sSessionID, media).then((res: visual) => {

        console.log("TimelineViewSidePanelComponent_UploadedLogoFile : Response ==>", res);
        this.oUploadedLogo = res;
        this.sUploadedLogoName = file.name;
        this.fLooknFeelForm.patchValue({ oUploadedLogo: this.oUploadedLogo, nUploadedLogoSize: this.nUploadedLogoSize });

        this.showLogoUploadModal = false;

      }).catch(error => {
        console.error("TimelineViewSidePanelComponent_UploadedLogoFile : Error ==>", error);
        this.oUploadedLogo = null;
        this.showLogoUploadModal = false;
        this.sDisplayResponseErrorMessage = error.error.errorMessage;
        this.bDisplayErrorMessage = true;
        setTimeout(() => { this.bDisplayErrorMessage = false; }, 5000);
        this.closeModal.nativeElement.click();
      });
    }



    /* var oFormData: any = new FormData();
     oFormData.append('session_id',this.sSessionID);
     oFormData.append('email',this.sUserEmail);
     oFormData.append('file',file);
     oFormData.append("logo", true);

     try
       {
         await this.oDataService.DataService_PostUploadedVisuals(oFormData).subscribe((result : any) =>
         {
         console.log("TimelineViewSidePanelComponent_UploadedLogoFile : Response ==>", result);
         this.oUploadedLogo = result;
         this.sUploadedLogoName = result.filename;
         this.fLooknFeelForm.patchValue({oUploadedLogo : this.oUploadedLogo, nUploadedLogoSize : this.nUploadedLogoSize});
         this.closeModal.nativeElement.click();
         },
         (error)=>
         {
           console.error("TimelineViewSidePanelComponent_UploadedLogoFile : Error ==>", error);
           this.oUploadedLogo=null;
           this.sDisplayResponseErrorMessage = error.error.errorMessage;
           this.bDisplayErrorMessage = true;
           setTimeout(()=>{this.bDisplayErrorMessage = false;},5000);
         this.closeModal.nativeElement.click();
         });
       }
       catch (error)
       {
         console.error("TimelineViewSidePanelComponent_UploadedLogoFile : ERROR ==>", error);
       } */
  }
  TimelineViewSidePanelComponent_GetPrimaryColor(event) {
    const primaryColor = event.target.value;
    console.log("Primary Color ==>", primaryColor);
    this.fLooknFeelForm.patchValue({ color: primaryColor })
    this.oPrimaryColor = primaryColor;
  }
  TimelineViewSidePanelComponent_GetFontColor(event) {
    const fontColor = event.target.value;
    console.log("Font Color ==>", fontColor);
    this.fLooknFeelForm.patchValue({ font_color: fontColor })
    this.oFontColor = fontColor;
  }
  TimelineViewSidepanelsComponent_SaveLooknFeelForm() {
    this.bRevertSettings = false;
    console.log("the Video Form Values are===>>", this.fLooknFeelForm.value);
    this.sSessionService.cSessionService_SetLooknFeelForm(this.fLooknFeelForm.value);
  }
  TimelineViewSidepanelsComponent_RevertLooknFeelForm() {
    this.TimelineViewSidePanelsComponent_SetVideoForm();
  }

  TimelineViewSidepanelsComponent_RemoveTagListElements(index) {
    this.lKey_terms.splice(index, 1);
    this.sSessionService.cSessionService_SetLooknFeelForm(this.fLooknFeelForm.value);
  }
  TimelineViewSidepanelsComponent_FocusoutFromInput() {
    if (this.inputValue !== "") {
      this.lKey_terms.push(this.inputValue);
      this.fLooknFeelForm.patchValue({ key_terms: this.lKey_terms });
    }
    setTimeout(() => { this.bDisplayAddInputField = false; }, 100);
  }
  TimelineViewSidepanelsComponent_ToggleTagButtonAndAddInputField() {
    this.bDisplayAddInputField = false;
    this.lKey_terms.push(this.inputValue);
    console.log("key ", this.lKey_terms);
    this.fLooknFeelForm.patchValue({ key_terms: this.lKey_terms });
    this.inputValue = "";
    this.sSessionService.cSessionService_SetLooknFeelForm(this.fLooknFeelForm.value);
  }
  TimelineViewSidepanelsComponent_EnterKeyTermsTag(keycode) {
    if (keycode == 13 && this.inputValue != "" || keycode == 188 && this.inputValue != "") {
      this.TimelineViewSidepanelsComponent_ToggleTagButtonAndAddInputField();
    }
  }
  TimelineViewSidepanelsComponent_GetInputValue(event) {
    this.inputValue = event.target.value;
    console.log("Input Value ==>", this.inputValue);
  }
  TimelineViewSidepanelsComponent_ToggleTagButtonAndAddInput() {
    this.inputValue = "";
    setTimeout(() => this.AddInputTagElement.nativeElement.focus());
    this.bDisplayAddInputField = true;
  }
  /* InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.text = `

    Coloris({
      el: '.coloris',
      swatches: [
          '#264653',
          '#2a9d8f',
          '#e9c46a',
          '#f4a261',
          '#e76f51',
          '#d62828',
          '#023e8a',
          '#0077b6',
          '#0096c7',
          '#00b4d8',
          '#48cae4',
      ]
  });
  $(document).ready(function () {
    $('.address').change(function () {
        if (this.checked)
            $('.address-div').fadeIn('slow');
        else
            $('.address-div').fadeOut('slow');
    });

    $('.website').change(function () {
        if (this.checked)
            $('.website-div').fadeIn('slow');
        else
            $('.website-div').fadeOut('slow');

    });

    $('.phone').change(function () {
        if (this.checked)
            $('.phone-div').fadeIn('slow');
        else
            $('.phone-div').fadeOut('slow');

    });
});
$(function ($) {
  $.autofilter();
});
$("[data-sidebar]").click(function() {
  var sideBarId = $(this).attr("data-sidebar");
  $(".rightSideBar").removeClass("active");
  $(".sidebarOverlay").addClass("active");
  $(sideBarId).addClass("active");
});


$(".sidebarOverlay, .closeRightSideBar , .closeSideBar").click(function() {
  $(".rightSideBar").removeClass("active");
  $(".sidebarOverlay").removeClass("active");
  $("body").removeClass("sidbar_contentslide");
});

$("[data-sidebar2]").click(function() {
  var sideBarId = $(this).attr("data-sidebar2");
  $(".rightSideBarTutorialsVideo").removeClass("active");
  $(".sidebarOverlay2").addClass("active");
  $(sideBarId).addClass("active");
});


$(".sidebarOverlay2, .closeRightSideBar2 , .closeSideBar").click(function() {
  $(".rightSideBarTutorialsVideo").removeClass("active");
  $(".sidebarOverlay2").removeClass("active");
  $("body").removeClass("sidbar_contentslide");
});

// data-custom-select
$("[data-custom-select]").click(function() {
  $("body").addClass("sidbar_contentslide");
});
// end data-custom-select

  `;
    document.body.appendChild(chatScript);
  } */

  openPreviewVideo(): void {
    this.session.visiblePreviewVideo = true;
    this.session.visiblePreviewOverview = false;
    this.session.visibleAnalytics = false;
    this.session.visibleVideoSettings = false;
    this.session.VideoSettingEditClip = false;

    this.session.visibleVideo = false;
    this.session.visibleTitle = false;
    this.session.visibleAudio = false;
    this.session.visibleVoiceover = false;
    this.session.visibleTagline = false;
  }
  closePreviewVideo(): void {
    this.session.visiblePreviewVideo = false;

  }


  openPreviewOverview(): void {
    this.session.visiblePreviewOverview = true;
    this.session.visiblePreviewVideo = false;
    this.session.visibleAnalytics = false;
    this.session.visibleVideoSettings = false;
    this.session.VideoSettingEditClip = false;

    this.session.visibleVideo = false;
    this.session.visibleTitle = false;
    this.session.visibleAudio = false;
    this.session.visibleVoiceover = false;
    this.session.visibleTagline = false;

  }
  closePreviewOverview(): void {
    this.session.visiblePreviewOverview = false;
  }


  openAnalytics(): void {
    this.session.visibleAnalytics = true;
    this.session.visiblePreviewOverview = false;
    this.session.visiblePreviewVideo = false;
    this.session.visibleVideoSettings = false;
    this.session.VideoSettingEditClip = false;

    this.session.visibleVideo = false;
    this.session.visibleTitle = false;
    this.session.visibleAudio = false;
    this.session.visibleVoiceover = false;
    this.session.visibleTagline = false;

  }
  closeAnalytics(): void {
    this.session.visibleAnalytics = false;
  }


  openVideoSettings(): void {
    this.session.visibleVideoSettings = true;
    this.session.visiblePreviewOverview = false;
    this.session.visibleAnalytics = false;
    this.session.visiblePreviewVideo = false;
    this.session.VideoSettingEditClip = false;

    this.session.visibleVideo = false;
    this.session.visibleTitle = false;
    this.session.visibleAudio = false;
    this.session.visibleVoiceover = false;
    this.session.visibleTagline = false;
  }

  closeVideoSettings(): void {
    console.log(' CLOSE THIS VIDEO ');
    this.session.visibleVideoSettings = false;
  }
  updateVideoSettings(): void {
    console.log(" --  UPDATE VIDEO  video here ", this.videoManager.selectedVideo.others_setting);
    if (this.videoManager.selectedVideo.others_setting.url){
      console.log( " URL FOUND ....");
      this.videoManager.selectedVideo.others_setting.url = true;
      this.videoManager.selectedVideo.ci_dict.url = this.videoManager.selectedVideo.url;
    } else {
      this.videoManager.selectedVideo.ci_dict.url = undefined;
      this.videoManager.selectedVideo.url = undefined;
    }
    if (this.videoManager.selectedVideo.others_setting.phone){
      this.videoManager.selectedVideo.ci_dict.phone = this.videoManager.selectedVideo.phone;
    } else {
      this.videoManager.selectedVideo.ci_dict.phone = undefined;
      this.videoManager.selectedVideo.phone = undefined;
    }
    if (this.videoManager.selectedVideo.others_setting.address) {
      this.videoManager.selectedVideo.ci_dict.address = this.videoManager.selectedVideo.address;
    } else {
      console.log(" UNDEIFNED ADDRESS TOTALLy");
      this.videoManager.selectedVideo.ci_dict.address = undefined;
      this.videoManager.selectedVideo.address = undefined;
    }
    this.session.visibleVideoSettings = false;
  }

  ngOnDestroy() {

    this.session.visibleVideoSettings = false;
    this.session.visibleAnalytics = false;
    this.session.visiblePreviewOverview = false;
    this.session.visiblePreviewVideo = false;
    //clearTimeout(this.nSetPollingTimer);
    // const videoElement = <HTMLVideoElement>document.getElementById("myVideoO");
    // videoElement.pause();
    this.myVideoO.nativeElement.pause();
  }

  openLocation(): void {
    this.onOpen.emit();
    //this.visibleMedia = false;
  }

  CreateVideoOverViewComponent_RemoveTagListElements(index) {
    this.lKey_terms.splice(index, 1);
    this.videoManager.selectedVideo.key_terms = this.lKey_terms;
  }

  CreateVideoOverViewComponent_FocusoutFromInput() {
    if (this.inputValue !== "") {
      if (!this.lKey_terms)
        this.lKey_terms = [];
      this.lKey_terms.push(this.inputValue);
      this.videoManager.selectedVideo.key_terms = this.lKey_terms;

    }
    setTimeout(() => { this.bDisplayAddInputField = false; }, 100);
  }
  CreateVideoOverViewComponent_ToggleTagButtonAndAddInputField() {
    this.bDisplayAddInputField = false;
    if (!this.lKey_terms)
      this.lKey_terms = [];

    this.lKey_terms.push(this.inputValue);

    this.inputValue = "";
    console.log("key ", this.lKey_terms);
  }
  CreateVideoOverViewComponent_EnterKeyTermsTag(keycode) {
    if (keycode == 13 && this.inputValue != "" || keycode == 188 && this.inputValue != "") {
      this.CreateVideoOverViewComponent_ToggleTagButtonAndAddInputField();
    }
  }

  CreateVideoOverViewComponent_ToggleTagButtonAndAddInput() {
    this.inputValue = "";
    setTimeout(() => this.AddInputTagElement.nativeElement.focus());
    this.bDisplayAddInputField = true;
  }
  CreateVideoOverViewComponent_GetInputValue(event) {
    this.inputValue = event.target.value;
    console.log("Input Value ==>", this.inputValue);
  }

  getStreamVideo() { 
    console.log("video streaming called");
    
    this.dataservice.getVideoStream(this.sUserEmail, this.sSessionID,this.videoManager.selectedVideo?.fpath).subscribe({
      next: (videoBlob) => {
        const videoURL = URL.createObjectURL(videoBlob); // Create an object URL for the Blob
        const videoElement = <HTMLVideoElement>document.getElementById("myVideoO");
        
        videoElement.src = videoURL; // Set the video source
        videoElement.load(); 
        videoElement.play();         // Load the new video
      videoElement.addEventListener('canplay', () => {
        console.log('Video is ready to play');
        this.canPlay = true;
      });
      },
      error: (error) => {
        console.error('Error streaming video:', error);
      }
    });
  }

}
