export const environment = {
  production: true,
  API_HOST: '/app/',
  WS_HOST: 'https://app.prontopia.com',
  API_HOST_ADMIN: '/admin/',
  WS_PATH: '/ws',
  PRONTO_USER_SESSION_TIMEOUT: 7200000, // 2 Hours
  SHOW_SAP : true,
  SHOW_SAP_FOR_SMB : false,
  ACTIVE_COHERE: true,
  ACTIVE_HUBSPOT: true,
   // for business plans
  business_plan : 'business',
  bDisplayLocales: false,
  IS_AZURE : true,
  LouderId: '65e1aa7aa1b1f49a4d99f443',
  client_id: '65e5f7c1a1b1f49a4d99fad0',
  // business_plan : 'business_plus',
  // business_plan : 'enterprise_agency',
  // business_plan : 'enterprise_franchesees',
};
