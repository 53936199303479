import { SearchData } from 'src/app/models/admin';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { video, videoLight } from '../../models/video';
import { environment } from '../../../environments/environment';
import { ApiURL } from 'src/app/conf/api-path';
// import { VideoManagerService } from 'src/app/Managers/video-manager.service';
import { VideoManagerService } from '../../Managers/video-manager.service';
import { VideoDataStore } from 'src/app/stores/video.store';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class VideoService {
    private videoManager: VideoManagerService;
    constructor(private http: HttpClient, private videoStore: VideoDataStore) {}

    public otherSystemFonts = undefined;
    getUserVideos(searchData: SearchData): Promise<unknown> {
        return this.http.post(environment.API_HOST_ADMIN + ApiURL.admin.user.user_videos, searchData).toPromise();
    }

    Videos(email, session_id, location_videos, publish_status, sortType, num_items, num_skip, order_by = undefined): Promise<unknown> {
        let sort = '';
        let orderByField = '';
        if (sortType == 'Last Modified') {
            sort = '&last_modified=true';
        } else {
            sort = '&created=true';
        }
        if (order_by) {
            orderByField = '&order_by=' + order_by;
        }

        let status = '';
        if (publish_status != '') {
            status = '&publish_status=' + publish_status;
        }

        return this.http
            .get(
                environment.API_HOST +
                    'videos?email=' +
                    email +
                    '&session_id=' +
                    session_id +
                    '&location_videos=' +
                    location_videos +
                    status +
                    '&num_items=' +
                    num_items +
                    '&num_skip=' +
                    num_skip +
                    sort +
                    orderByField,
            )
            .toPromise();
    }

    searchVideo(
        email,
        session_id,
        title,
        last_modified,
        publish_status,
        sortType,
        num_items,
        num_skip,
        order_by = undefined,
    ): Promise<unknown> {
        let sort = '';
        if (sortType == 'Last Modified') {
            sort = '&last_modified=true';
        } else {
            sort = '&created=true';
        }
        let status = '';
        if (publish_status != '') {
            status = '&publish_status=' + publish_status;
        }

        if (order_by != undefined) {
            status = '&order_by=' + order_by;
        }

        return this.http
            .get(
                environment.API_HOST +
                    'search_video?email=' +
                    email +
                    '&session_id=' +
                    session_id +
                    '&title=' +
                    title +
                    '&last_modified=' +
                    last_modified +
                    status +
                    '&num_items=' +
                    num_items +
                    '&num_skip=' +
                    num_skip +
                    sort,
            )
            .toPromise();
    }

    updateStatus(email, session_id, video_id, publish_status): Promise<unknown> {
        return this.http
            .post(environment.API_HOST + 'update_video_status', {
                email: email,
                session_id: session_id,
                video_id: video_id,
                publish_status: publish_status,
            })
            .toPromise();
    }

    replaceClip(email, session_id, video_id, replace_id): Promise<unknown> {
        return this.http
            .post(environment.API_HOST + 'replace_clip', {
                email: email,
                session_id: session_id,
                video_id: video_id,
                replace_id: replace_id,
            })
            .toPromise();
    }

    getUserVideoCount(email, session_id) {
        return this.http.get(environment.API_HOST + 'videos_count?email=' + email + '&session_id=' + session_id).toPromise();
    }

    generateVideo(email, session_id, video_id): Promise<unknown> {
        return this.http
            .post(environment.API_HOST + 'generate_video', {
                email: email,
                session_id: session_id,
                video_id: video_id,
            })
            .toPromise();
    }

    generateVideo2(email, session_id, video_id): Promise<unknown> {
        return this.http
            .post(environment.API_HOST + 'generate_video_v2', {
                email: email,
                session_id: session_id,
                video_id: video_id,
            })
            .toPromise();
    }

    get_video_duration(email, session_id, video_id): Promise<unknown> {
        return this.http
            .get(environment.API_HOST + 'video_duration?email=' + email + '&session_id=' + session_id + '&visual_id=' + video_id)
            .toPromise();
    }
    generatedVideoStatus(email, session_id, gv_task_id): Promise<unknown> {
        return this.http
            .get(environment.API_HOST + 'generate_video_status?email=' + email + '&session_id=' + session_id + '&gv_task_id=' + gv_task_id)
            .toPromise();
    }
    getvideoGenTaskIdByFpath(email, session_id, id): Promise<unknown> {
        return this.http
            .post(environment.API_HOST + 'get_video_task_id?email', {
                email: email,
                session_id: session_id,
                video_id: id,
            })
            .toPromise();
    }

    generatedAutomatedVideos(email, session_id): Promise<unknown> {
        return this.http.get(environment.API_HOST + 'automated_videos?email=' + email + '&session_id=' + session_id).toPromise();
    }

    saveScheduleVideo(payload): Promise<unknown> {
        return this.http.post(environment.API_HOST + 'schedule_video', payload).toPromise();
    }
    deleteScheduleVideo(email, session_id, schedule_id): Promise<unknown> {
        return this.http
            .request('delete', environment.API_HOST + 'schedule_video', {
                body: {
                    email: email,
                    session_id: session_id,
                    schedule_id: schedule_id,
                },
            })
            .toPromise();
    }

    deleteVideo(email, session_id, visual_id) {
        return this.http
            .delete(environment.API_HOST + 'delete_visual?email=' + email + '&session_id=' + session_id + '&visual_id=' + visual_id)
            .toPromise();
    }

    getScheduleVideos(email, session_id, schedule_id): Promise<unknown> {
        return this.http
            .get(environment.API_HOST + 'scheduled_videos?email=' + email + '&session_id=' + session_id + '&schedule_id=' + schedule_id)
            .toPromise();
    }

    getVideosByScheduleId(email, session_id, schedule_id): Promise<unknown> {
        return this.http
            .post(environment.API_HOST + 'scheduled_videos', {
                email: email,
                session_id: session_id,
                schedule_id,
            })
            .toPromise();
    }

    createVideo(video: video | videoLight) {
        if (!video.font_color || video.font_color == '') {
            delete video.font_color;
        }
        if (!video.theme_colors || video.theme_colors.length == 0) {
            delete video.theme_colors;
        }
        return this.http.post(environment.API_HOST + 'create_video', video).toPromise();
    }

    updateVideoTitle(video: any) {
        return this.http.post(environment.API_HOST + 'update_video_title', video).toPromise();
    }

    direct_localized_video(video: video) {
        return this.http.post(environment.API_HOST + 'direct_localized_video', video).toPromise();
    }

    getTemplateInfo(template_name: string) {
        return this.http.get(environment.API_HOST + 'template_info?template_name=' + template_name).toPromise();
    }
    getTemplateContent(template_name: string, type, duration) {
        return this.http
            .get(environment.API_HOST + 'template_content?template_name=' + template_name + '&type=' + type + '&duration=' + duration)
            .toPromise();
    }

    getVideo(email, session_id, video_id) {
        return this.http
            .get(environment.API_HOST + 'get_video?email=' + email + '&session_id=' + session_id + '&video_id=' + video_id)
            .toPromise();
    }
    getVideoById_V2(email, session_id, video_id) {
        return this.http
            .get(environment.API_HOST + 'get_video?email=' + email + '&session_id=' + session_id + '&video_id=' + video_id)
            .toPromise();
    }

    cloneVideo(email, session_id, video_id) {
        let formData = new FormData();
        formData.append('session_id', session_id);
        formData.append('email', email);
        formData.append('video_id', video_id);
        return this.http.post(environment.API_HOST + 'clone_video', formData).toPromise();
    }
    getSignedURL(email, session_id, video_id) {
        let formData = new FormData();
        formData.append('session_id', session_id);
        formData.append('email', email);
        formData.append('fpath', video_id);
        return this.http.post(environment.API_HOST + 'signed_url_for_download', formData).toPromise();
    }

    getBatchSignedURL(email, session_id, urls: string[]) {
        let formData = new FormData();
        formData.append('session_id', session_id);
        formData.append('email', email);
        formData.append('fpaths', JSON.stringify(urls));
        return this.http
            .post(environment.API_HOST + 'signed_url_multi_download', {
                session_id: session_id,
                email: email,
                fpaths: urls,
            })
            .toPromise();
    }

    getThumbBatchSignedURL(email, session_id, urls: string[]) {
        let formData = new FormData();
        formData.append('session_id', session_id);
        formData.append('email', email);
        formData.append('fpaths', JSON.stringify(urls));
        return this.http
            .post(environment.API_HOST + 'get_thumbnails', {
                session_id: session_id,
                email: email,
                fpaths: urls,
            })
            .toPromise();
    }

    addcomment(video_id, comment) {
        return this.http
            .post(environment.API_HOST + 'video_comment', {
                video_id: video_id,
                comment: comment,
            })
            .toPromise();
    }

    deleteComment(video_id, comment) {
        return this.http.delete(environment.API_HOST + 'video_comment?video_id=' + video_id + '&comment=' + comment).toPromise();
    }
    guestUser(guest_email) {
        return this.http.post(environment.API_HOST + 'guest', { email: guest_email }).toPromise();
    }
    getAllSignedURLs(data) {
        return this.http.post(environment.API_HOST + 'signed_url_multi_download', data).toPromise();
    }

    convertVideo(data: any) {
        return this.http.post(environment.API_HOST + 'make_ar_video', data).toPromise();
    }

    async checkVideoStatus(email, session, id, videoList) {
        this.getvideoGenTaskIdByFpath(email, session, id).then((res: any) => {
            if (res.task_id) {
                this.monitorVideoGenerationProcessing(email, session, res.task_id, id, videoList);
            }
        });
    }
    async monitorVideoGenerationProcessing(email, session, task_id, id, videoList) {
        let __this = this;
        this.generatedVideoStatus(email, session, task_id).then((res: any) => {
            if (res.task_status && (res.task_status == 'in-progress' || res.task_status == 'waiting')) {
                if (!this.videoStore.clearGenerationInterval) {
                    setTimeout(() => {
                        __this.monitorVideoGenerationProcessing(email, session, task_id, id, videoList);
                    }, 2000);
                } else {
                    console.log(' Search for STATUS Aborted .............');
                }
            } else {
                if (videoList) {
                    let selectedClip = videoList.filter((item: any) => {
                        if (item.fpath == id) {
                            item.status = 'success';
                        }
                        return item;
                    });
                    if (selectedClip && selectedClip.length > 0) {
                        selectedClip[0].status = 'success';
                    }
                }
            }
        });
    }

    firstTimeCreateVideoMixPixelApi(data): Promise<unknown> {
        return this.http.post(environment.API_HOST + ApiURL.user.mixpixel.create_video_enter, data).toPromise();
    }

    async getVideoData(email, session_id, video_id) {
        console.log( email, session_id, video_id );
        return new Promise(async (resolve, reject) => {
            let selectedVideo = undefined;
            await this.getVideo(email, session_id, video_id).then(async (res) => {
                selectedVideo = res['video'] as video;
                await this.getSignedURL(email, session_id, video_id).then((result) => {
                    console.log("url video ", result['url'])
                    selectedVideo.video_url = result['url'];
                }).catch( (err) =>{
                    console.log("signed url error ", err);
                });
                if (selectedVideo.thumb != ''){
                    console.log('Get video Signed url thumbnail...');
                    await this.getSignedURL(email, session_id, selectedVideo.thumb).then((resThumb) => {
                        console.log( "url thumb ", resThumb );
                        selectedVideo.thumbnail = resThumb['url'];
                    });
                }
            });
            return resolve(selectedVideo);
        });
    }

  getVideoStream(email: string, sessionId: string, videoId: string): Observable<Blob> {
    const apiUrl = `${environment.API_HOST}stream_video`;
    const payload = {
      email: email,
      session_id: sessionId,
      video_id: videoId
    };

    return this.http.post(apiUrl, payload, {
      responseType: 'blob' // Expecting binary data as the response
    });
  }
}
